<template>
  <div id="addNotification" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base">
      <v-row no-gutters class="titulo ml-10 mt-5">EDITAR PREGUNTA</v-row>
      <v-container fluid class="addForms" style="margin-top: 7%">
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="5" sm="4" class="ml-5 formularios">
            <v-row>
              <!--Guia de referencia-->
              <label for="guia">Guía de Referencia</label>
              <select
                name="guia"
                id="guia"
                v-model="pregunta.guiaReferencia"
                @change="buscaID()"
                disabled=""
              >
                <option selected disabled value="0">Seleccione</option>
                <option value="1">I</option>
                <option value="2">II</option>
                <option value="3">III</option>
              </select>
            </v-row>
            <v-row>
              <!--ID Pregunta-->
              <label for="idPregunta">Id Pregunta</label>
              <input
                type="text"
                name="idPregunta"
                id="idPregunta"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="pregunta.consecutivoGuiaPregunta"
                maxlength="20"
                readonly
                @keypress="alfaNumerico($event)"
              />
            </v-row>

            <v-row class="mb-2">
              <!--ID Pregunta-->
              <label for="existe">Existe sección</label>
              <input
                type="checkbox"
                name="existe"
                id="existe"
                v-model="existe"
              />
            </v-row>
            <v-row class="mb-2" v-if="existe">
              <!--ID Pregunta-->
              <label for="seccionSelect">Sección</label>
              <select
                name="seccionSelect"
                id="seccionSelect"
                v-model="pregunta.seccion"
              >
                <option selected disabled value="0">Seleccione</option>
                <option
                  v-for="secc in secciones"
                  :key="secc"
                  v-bind:value="secc"
                >
                  {{ secc }}
                </option>
              </select>
            </v-row>
            <v-row v-else>
              <!--Seccion-->
              <label for="seccion">Seccion</label>
              <input
                type="text"
                name="seccion"
                id="seccion"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="pregunta.seccion"
                maxlength="200"
                @keypress="alfaNumerico($event)"
              />
            </v-row>
            <v-row>
              <!--ID Pregunta-->
              <label for="question">Pregunta</label>
              <input
                type="text"
                name="question"
                id="question"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="pregunta.pregunta"
                maxlength="200"
                @keypress="alfaNumerico($event)"
              />
            </v-row>
            <v-row>
              <!--Pregunta para area de servicio-->
              <label for="area">Pregunta para área de servicio</label>
              <select
                name="ara"
                id="area"
                v-model="pregunta.preguntaAreaServicio"
              >
                <option value="1">Sí</option>
                <option value="2">No</option>
              </select>
            </v-row>
            <v-row>
              <!--Pregunta para area de servicio-->
              <label for="cargo">
                Pregunta para personal con gente a su cargo
              </label>
              <select
                name="cargo"
                id="cargo"
                v-model="pregunta.preguntaPersonalACargo"
              >
                <option value="1">Sí</option>
                <option value="2">No</option>
              </select>
            </v-row>
            <v-row>
              <!--Tipo de pregunta-->
              <label for="tipoPregunta"> Tipo de Pregunta </label>
              <select
                name="tipoPregunta"
                id="tipoPregunta"
                v-model="pregunta.tipoPregunta"
              >
                <option value="0">Sin tipo</option>
                <option value="1">Tipo 1</option>
                <option value="2">Tipo 2</option>
              </select>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="7" align-self="center">
            <!--Acciones-->
            <button class="botonAmarillo mt-5 ml-6" @click="validacion()">
              Guardar
            </button>
            <router-link to="/questions" id="backQuestion">
              <button class="botonAmarilloDerecho">Regresar</button>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Edición Pregunta</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="center">{{ respuesta }}</h3>
          <br />
          <ul style>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            ¿Esta seguro de modificar la pregunta?
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="aux()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="actualizar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Pregunta NOM-035</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-sm-center text-md-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="aux()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      errors: [],
      respuesta: "",
      advice: false,
      error: false,
      confirmation: false,
      pregunta: [],
      existe: true,
      secciones: [],
    };
  },
  methods: {
    actualizar() {
      this.advice = false;
      this.show = true;
      axios
        .put(
          `${Server}/climaLaboralPreguntas/${this.pregunta.id}`,
          {
            Id: this.pregunta.id,
            GuiaReferencia: parseInt(this.pregunta.guiaReferencia),
            ConsecutivoGuiaPregunta: parseInt(
              this.pregunta.consecutivoGuiaPregunta
            ),
            Seccion: this.pregunta.seccion,
            Pregunta: this.pregunta.pregunta,
            PreguntaAreaServicio:
              this.pregunta.preguntaAreaServicio == "1" ? true : false,
            PreguntaPersonalACargo:
              this.pregunta.preguntaPersonalACargo == "1" ? true : false,
            FechaAlta: this.pregunta.fechaAlta,
            FechaUltimaPublicacion: this.pregunta.fechaUltimaPublicacion,
            TipoPregunta: this.pregunta.tipoPregunta,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.pregunta = [];
          this.respuesta = "La pregunta fue modificada con éxito";
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.pregunta = [];
            this.respuesta = "La pregunta no fue modificada";
            this.confirmation = true;
          }
        });
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9?¿. ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    validacion: function () {
      this.errors = [];
      if (this.pregunta.GuiaReferencia == 0) {
        this.errors.push("Se debe especificar la guía de Referencia.");
      }
      if (this.pregunta.ConsecutivoGuiaPregunta == " ") {
        this.errors.push("Se debe especificar el id de la pregunta.");
      }
      if (this.pregunta.Pregunta == "" || this.pregunta.Pregunta == " ") {
        this.errors.push("Se debe especificar la pregunta.");
      }

      if (
        this.pregunta.pregunta.Seccion == "" ||
        this.pregunta.pregunta.Seccion == " "
      ) {
        this.errors.push("Se debe especificar la sección.");
      }
      if (this.pregunta.PreguntaAreaServicio == 0) {
        this.errors.push(
          "Se debe especificar sí es o no para área de servicio."
        );
      }
      if (this.pregunta.PreguntaPersonalACargo == 0) {
        this.errors.push(
          "Se debe especificar sí es para personal con gente a cargo."
        );
      }
      if (this.errors.length == 0) {
        return (this.advice = true);
      } else {
        this.respuesta = "Por favor corrige el(los) siguiente(s) error(es):";
        this.error = true;
      }
    },
    aux() {
      this.advice = false;
      this.confirmation = false;
      this.pregunta = [];
      this.$router.push("/questions");
    },
    buscaID() {
      this.show = false;
      axios
        .get(
          `${Server}/climaLaboralPreguntas/numPregunta/${this.pregunta.guiaReferencia}`,
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.idPregunta = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listar() {
      this.show = true;
      axios
        .get(
          `${Server}/climaLaboralPreguntas/guia/${this.pregunta.guiaReferencia}`,
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          var auxSeccion = response.data;
          var temp = [];
          auxSeccion.forEach((value, index) => {
            if (value.seccion != "") {
              temp.push(value.seccion);
            }
          });
          const myObj = {};
          this.secciones = [];
          temp.forEach((el) => {
            // comprobamos si el valor existe en el objeto
            if (!(el in myObj)) {
              // si no existe creamos ese valor y lo añadimos al array final, y si sí existe no lo añadimos
              myObj[el] = true;
              this.secciones.push(el);
            }
          });
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    recuperar() {
      var x = (document.getElementById("guia").disabled = false);
      //console.log("recuperacion");
      this.show = true;
      axios
        .get(
          `${Server}/climaLaboralPreguntas/${this.$route.params.idQuestion}`,
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.pregunta = response.data;
          if (this.pregunta.preguntaAreaServicio)
            this.pregunta.preguntaAreaServicio = "1";
          else this.pregunta.preguntaAreaServicio = "2";

          if (this.pregunta.preguntaPersonalACargo)
            this.pregunta.preguntaPersonalACargo = "1";
          else this.pregunta.preguntaPersonalACargo = "2";
          this.show = false;
          this.listar();
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.recuperar();
  },
};
</script>
